<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນໃບສະເໜີລາຄາ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ສ້າງໃບສະເໜີລາຄາ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #pps_price_total="row">
                <td class="text-right">{{row.item.pps_price_total | numFormat(',000.00')}}</td>
              </template>
              <template #amount_vat="row">
                <td class="text-right">{{row.item.amount_vat | numFormat(',000.00')}}</td>
              </template>
              <template #pps_status="{item}">
                <td>
                  <CBadge :color="getBadge(item.pps_status)">
                    <strong v-if="item.pps_status==0">ລໍາຖ້າຮຽກເກັບ</strong>
                    <strong v-if="item.pps_status==1">ຮຽກເກັບແລ້ວ</strong>
                  </CBadge>
                </td>
              </template>
              <template #appl_status="{item}">
                <td>
                  <CBadge :color="getBadge(item.appl_status)">
                    <strong v-if="item.appl_status==0">ລໍາຖ້າເບີກ</strong>
                    <strong v-if="item.appl_status==1">ເບິກແລ້ວ</strong>
                  </CBadge>
                </td>
              </template>
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem
                      :disabled="userGroup != 'administrator'"
                      v-on:click="onClickView(row.item)"
                    >ລາຍລະອຽດ</CDropdownItem>
                    <CDropdownItem
                      v-on:click="onClickUpdate(row.item)"
                    >ແກ້ໄຂ</CDropdownItem>
                    <CDropdownItem
                      :disabled="row.item.pps_status == 1 || row.item.appl_status == 1 || userGroup != 'administrator'"
                      v-on:click="onClickDelete(row.item)"
                    >ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CModal
      title="ຟອມສ້າງໃບສະເໜີລາຄາ ( Quotations )"
      size="lg"
      :show.sync="modalCreate"
      :closeOnBackdrop="false"
    >
      <proposalFormCreate
        :cus="cusList"
        :service="serviceList"
        @on_success="onSubmitCreate"
        @on_cancle="onSubmitCancle"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ລາຍລະອຽດໃບສະເໜີລາຄາ ( Quotations )"
      size="lg"
      :show.sync="modalView"
      :closeOnBackdrop="false"
    >
      <proposalView :reportPps="reportPps" :reportPssList="reportPssList" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal
      title="ແກ້ໄຂໃບສະເໜີລາຄາ"
      size="lg"
      :show.sync="modalUpdate"
      :closeOnBackdrop="false"
    >
      <proposalFormUpdate :pps="pps" @on_success="onSubmitUpdate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import { getProposalAPI, deleteProposalAP, updateProposalAPI } from "@/api/proposal";
import { getCustomerAPI } from "@/api/customer";
import { getServiceAPI } from "@/api/service";
import { getReportProposalByIdAPI } from "@/api/report-proposal";
import { mapActions } from "vuex";
import proposalFormCreate from "./components/ProposalFormCreate";
import proposalView from "./components/ProposalView";
import proposalFormUpdate from "./components/ProposalFormUpdate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "Proposal",
  components: {
    proposalFormCreate,
    proposalView,
    proposalFormUpdate,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      modalView: false,
      modalUpdate: false,
      items: [],
      fields: [
        { key: "id", label: "ລໍາດັບ" },
        { key: "pps_no", label: "ເລກທີໃບສະເໜີ" },
        { key: "pps_date", label: "ວັນທີ" },
        { key: "cus_full_name", label: "ຊື່ລຸກຄ້າ" },
        { key: "pps_price_total", label: "ຈໍານວນເງິນ" },
        { key: "amount_vat", label: "ອາກອນ" },
        { key: "pps_curency", label: "ສະກຸນເງິນ" },
        { key: "pps_status", label: "ສະຖານະ" },
        { key: "appl_status", label: "ອຸປະກອນ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      cusList: [],
      serviceList: [],
      reportPps: {},
      reportPssList: [],
      pps: {},
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    ...mapActions("proposal", ["proposalCreate", "proposalClear"]),
    getBadge(status) {
      return status === "0" ? "danger" : status === "1" ? "success" : "success";
    },
    // Load data
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getProposalAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
      getCustomerAPI().then((response) => {
        this.cusList = response.data.data;
      });
      getServiceAPI().then((response) => {
        this.serviceList = response.data.data;
      });
    },

    onSubmitCreate(credentials) {
      this.proposalCreate(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },
    onSubmitCancle() {
      this.proposalClear().then((response) => {
        this.initialGetData();
      });
      this.modalCreate = false;
    },
    onClickView(item) {
      getReportProposalByIdAPI(item.pps_no).then((response) => {
        this.reportPps = response.data.data;
        this.reportPssList = response.data.data.list;
        this.modalView = true;
      });
    },
    onClickUpdate(item) {
      this.pps = item;
      this.modalUpdate = true;
    },
    onSubmitUpdate(credentials) {
      updateProposalAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdate = false;
    },
    onClickDelete(item) {
      let onOk = () => {
        deleteProposalAP(item.pps_no)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>
