<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div v-if="!step1">
          <CCard border-color="success">
            <CCardHeader>ກະລຸນາປ້ອນຂໍ້ມູນ</CCardHeader>
            <CCardBody>
              <ValidationObserver v-slot="{ invalid }">
                <CForm ref="form" :model="form" @submit.prevent="handleAddCus">
                  <CRow>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ເລືອກການສະເໜີ :</label>
                          <v-select
                            prop="pps_project"
                            v-model="form.pps_project"
                            :options="projectSelect"
                            :reduce="code => code.code"
                            label="message"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ລະຫັດລູກຄ້າ :</label>
                          <v-select
                            prop="cus_id"
                            v-model="form.cus_id"
                            :options="cus"
                            :reduce="cus_id => cus_id.cus_id"
                            label="cus_id"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ສະກຸນເງິນ :</label>
                          <v-select
                            prop="pps_curency"
                            v-model="form.pps_curency"
                            :options="curencyList"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <CInput
                          label="ອັດຕາແລກປ່ຽນຂອງສະກຸນເງິນທີ່ເລືອກ :"
                          prop="pps_rate_curency"
                          v-model="form.pps_rate_curency"
                        />
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <CInput
                          label="ອາກອນມູນຄ່າເພີ້ມ (%) :"
                          type="number"
                          prop="pps_vat"
                          v-model="form.pps_vat"
                        />
                      </validation-provider>
                    </CCol>

                    <CCol sm="6" v-if="userGroup == 'administrator'">
                      <div class="form-group">
                        <label>ສະແດງລາຍເຊັນ :</label>
                        <v-select
                          prop="pps_signature"
                          v-model="form.pps_signature"
                          :options="signatureList"
                          :reduce="code => code.code"
                          label="message"
                        />
                      </div>
                    </CCol>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <CInput
                          label="ຂໍ້ຄວາມໃນ Remark ບິນ :"
                          prop="pps_remark"
                          v-model="form.pps_remark"
                        />
                      </validation-provider>
                    </CCol>
                    <CCol sm="12" v-if="form.pps_project == 1">
                      <CTextarea
                        label="ໂຄງການ :"
                        prop="pps_project_name"
                        v-model="form.pps_project_name"
                        rows="2"
                      />
                    </CCol>
                    <CCol sm="12">
                      <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                        <CIcon name="cil-check" />ຕົກລົງ
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </ValidationObserver>
            </CCardBody>
          </CCard>
        </div>
        <div v-if="step1">
          <CCard border-color="success">
            <CCardHeader>ກະລຸນາປ້ອນຂໍ້ມູນ</CCardHeader>
            <CCardBody>
              <ValidationObserver v-slot="{ invalid }">
                <CForm ref="form" :model="form" @submit.prevent="handleAddSv">
                  <CRow>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ບໍລິການ :</label>
                          <v-select
                            prop="service_id"
                            v-model="form.service_id"
                            :options="service"
                            :reduce="service_id => service_id.service_id"
                            label="service_name"
                            @input="handleCheckPrice"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <CInput
                          label="ຈໍານວນ :"
                          type="number"
                          prop="pps_amount"
                          v-model="form.pps_amount"
                        />
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <validation-provider rules="required">
                        <CInput label="ລາຄາ / ຫົວໜ່ວຍ: " prop="pps_price" v-model="form.pps_price" />
                      </validation-provider>
                    </CCol>
                    <CCol sm="6">
                      <CInput
                        label="ໝາຍເຫດ :"
                        prop="pps_remark_list"
                        v-model="form.pps_remark_list"
                      />
                    </CCol>
                    <CCol sm="12">
                      <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                        <CIcon name="cil-plus" />ເພີ້ມບໍລິການ
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </ValidationObserver>
            </CCardBody>
          </CCard>
        </div>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <CRow>
          <CCol sm="12">
            <h5 class="text-center">ຂໍ້ມູນລູກຄ້າ</h5>
          </CCol>
          <CCol sm="4" class="text-right">ຊື່ :</CCol>
          <CCol sm="8">{{cusServiceInfo.cus_full_name}}</CCol>
          <CCol sm="4" class="text-right">ເບີໂທ :</CCol>
          <CCol sm="8">{{cusServiceInfo.cus_tel}}</CCol>
          <CCol sm="4" class="text-right">ທີ່ຢູ່ :</CCol>
          <CCol sm="8">{{cusServiceInfo.cus_address}}</CCol>
          <CCol sm="4" class="text-right">ຊື່ໂຄງການ :</CCol>
          <CCol sm="8">{{cusServiceInfo.pps_project_name}}</CCol>
          <CCol sm="4" class="text-right">ສະກຸນເງິນ :</CCol>
          <CCol sm="2">{{cusServiceInfo.pps_curency}}</CCol>
          <CCol sm="6">ອັດຕາແລກປ່ຽນ : {{form.pps_rate_curency | numFormat}}</CCol>
          <CCol sm="4" class="text-right">ອາກອນມູນຄ່າເພີ້ມ :</CCol>
          <CCol sm="8">{{cusServiceInfo.pps_vat}} %</CCol>
          <CCol sm="4" class="text-right">ຂໍ້ຄວາມໃນ Remark ບິນ :</CCol>
          <CCol sm="8">{{cusServiceInfo.pps_remark}}</CCol>
          <CCol sm="12" class="text-center">
            <strong>ລາຍການການບໍລິການ</strong>
          </CCol>
          <CCol sm="12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">ລໍາດັບ</th>
                  <th scope="col">ລາຍການ</th>
                  <th scope="col">ຈໍານວນ</th>
                  <th scope="col">ລາຄາ</th>
                  <th scope="col">ລວມ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in serviceList" :key="row.index">
                  <th>{{row.value.index}}</th>
                  <th>{{row.value.service_name}} ({{row.value.pps_remark_list}})</th>
                  <th class="text-center">{{row.value.pps_amount | numFormat}}</th>
                  <th class="text-right">{{row.value.pps_price | numFormat(',000.00')}}</th>
                  <th class="text-right">{{row.value.service_total | numFormat(',000.00')}}</th>
                  <th class="text-center">
                    <span @click="removeList(row.value)">
                      <CIcon name="cil-x" />
                    </span>
                  </th>
                </tr>
                <tr>
                  <td colspan="4" class="text-right">
                    <strong>ລວມທັງໝົດ:</strong>
                  </td>
                  <td class="text-right">
                    <strong>{{sumTotal | numFormat(',000.00')}}</strong>
                  </td>
                  <td class="text-right">{{cusServiceInfo.pps_curency}}</td>
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12">
        <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
          <CRow>
            <CCol sm="12">
              <div class="float-right">
                <CButton type="submit" variant="outline" color="primary">
                  <CIcon name="cil-save" />ບັນທຶກ
                </CButton>
                <CButton @click="handleCancle" variant="outline" color="warning">
                  <CIcon name="cil-x" />ຍົກເລີກ
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getServiceByIdAPI } from "@/api/service";
import { mapActions } from "vuex";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ProposalFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    cus: {
      type: Array,
      required: true,
    },
    service: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      step1: false,
      form: {
        pps_project: 0,
        pps_project_name: "",
        pps_curency: "KIP",
        pps_rate_curency: 1,
        pps_vat: "0",
        pps_remark: "This pricess is Not Include VAT 10%",
        cus_id: "",
        service_id: "",
        pps_price: "",
        pps_amount: 1,
        pps_remark_list: "",
        pps_signature: 0,
      },
      projectSelect: [
        { code: 0, message: "ຕິດຕັ້ງທົ່ວໄປ" },
        { code: 1, message: "ຕິດຕັ້ງໂຄງການ" },
      ],
      signatureList: [
        { code: 0, message: "ບໍ່ສະແດງ" },
        { code: 1, message: "ສະແດງ" },
      ],
      curencyList: ["KIP", "THB", "USD"],
      cusServiceInfo: {},
      serviceList: [],
      service_price: "",
      sumTotal: 0,
    };
  },
  methods: {
    ...mapActions("proposal", [
      "proposalAddCus",
      "proposalAddSv",
      "proposalRemoveSv",
    ]),
    handleAddCus() {
      let pps_project = this.form.pps_project;
      let pps_project_name = this.form.pps_project_name;
      let pps_curency = this.form.pps_curency;
      let pps_vat = this.form.pps_vat;
      let pps_remark = this.form.pps_remark;
      let pps_by = decrypt(this.$store.getters.getFullName);
      let pps_signature = this.form.pps_signature;
      let cus_id = this.form.cus_id;
      this.proposalAddCus({
        pps_project,
        pps_project_name,
        pps_curency,
        pps_vat,
        pps_remark,
        pps_by,
        pps_signature,
        cus_id,
      })
        .then((response) => {
          // Refresh data on applAddList
          this.cusServiceInfo = this.$store.getters.getProposalCusInfo;
          this.step1 = true;
        })
        .catch((error) => {
          this.$awn.alert("Add Error, Please try again");
        });
    },
    handleAddSv() {
      let service_id = this.form.service_id;
      let pps_price = this.form.pps_price;
      let pps_amount = this.form.pps_amount;
      let pps_remark_list = this.form.pps_remark_list;
      this.proposalAddSv({
        service_id,
        pps_price,
        pps_amount,
        pps_remark_list,
      })
        .then((response) => {
          // Refresh data on applAddList
          this.serviceList = this.$store.getters.getProposalSvListInfo;
          this.sumTotal = this.$store.getters.getProposalTotal;
          // Reset data
          this.form.service_id = "";
          this.form.pps_price = "";
          this.form.pps_amount = 1;
          this.form.pps_remark_list = "";
        })
        .catch((error) => {
          this.$awn.alert("Add Error, Please try again");
        });
    },
    removeList(value) {
      this.proposalRemoveSv(value)
        .then((response) => {
          // Refresh data on applAddList
          this.serviceList = this.$store.getters.getProposalSvListInfo;
          this.sumTotal = this.$store.getters.getProposalTotal;
          this.$awn.success("Delete success");
        })
        .catch((error) => {
          this.$awn.alert("Delete Error, Please try again");
        });
      // console.log(value);
    },
    handleCancle() {
      this.$emit("on_cancle", {});
      //clear
      this.step1 = false;
      this.form.pps_project = 0;
      this.form.pps_project_name = "";
      this.form.pps_curency = "KIP";
      this.form.pps_rate_curency = 1;
      this.form.pps_vat = "0";
      this.form.pps_signature = 0;
      this.form.cus_id = "";
      this.form.service_id = "";
      this.form.pps_price = "";
      this.form.pps_amount = 1;
      this.form.pps_remark_list = "";
      this.cusServiceInfo = {};
      this.serviceList = [];
      this.sumTotal = 0;
    },
    handleCheckPrice() {
      getServiceByIdAPI(this.form.service_id).then((response) => {
        this.form.pps_price =
          Number(response.data.data.service_price) /
          Number(this.form.pps_rate_curency);
      });
    },
    handleSubmit() {
      this.$emit("on_success", {
        cusServiceInfo: this.cusServiceInfo,
        serviceList: this.serviceList,
        sumTotal: this.sumTotal,
      });
      //clear
      this.step1 = false;
      this.form.pps_project = 0;
      this.form.pps_project_name = "";
      this.form.pps_curency = "KIP";
      this.form.pps_rate_curency = 1;
      this.form.pps_vat = "0";
      this.form.cus_id = "";
      this.form.service_id = "";
      this.form.pps_price = "";
      this.form.pps_amount = 1;
      this.form.pps_remark_list = "";
      this.cusServiceInfo = {};
      this.serviceList = [];
      this.sumTotal = 0;
    },
  },
};
</script>