import axios from '@/libs/api.request'


export const getReportProposalByIdAPI = (id) => {
    return axios.request({
        url: 'report-proposal/' + id,
        method: 'get'
    })
}

export const getReportProposalApplByIdAPI = (id) => {
    return axios.request({
        url: 'report-proposal-appliance/' + id,
        data: credentials,
        method: 'get'
    })
}