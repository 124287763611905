<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CRow>
          <CCol sm="1"></CCol>
          <CCol sm="3">ເລກທີໃບສະເໜີລາຄາ :</CCol>
          <CCol sm="2">{{pps.pps_no}}</CCol>
          <CCol sm="3" class="text-right">ວັນທີ :</CCol>
          <CCol sm="3">{{pps.pps_date}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ຊື່ ແລະ ນາມສະກຸນ :</CCol>
          <CCol sm="8">{{pps.cus_full_name}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ເບີໂທ :</CCol>
          <CCol sm="8">{{pps.cus_tel}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ທີ່ຢູ່ :</CCol>
          <CCol sm="8">{{pps.cus_address}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ໂຄງການ :</CCol>
          <CCol sm="8">{{pps.inv_project_name}}</CCol>
          <CCol sm="1"></CCol>
          <CCol sm="3">ຈໍານວນເງິນທັງໝົດ :</CCol>
          <CCol sm="8">{{pps.sum_total | numFormat(',000.00')}} {{pps.pps_curency}}</CCol>
        </CRow>
      </CCol>
      <CCol sm="12">
        <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
          <CRow>
            <CCol sm="1"></CCol>
            <CCol sm="4">
              <CInput
                label="ອາກອນມູນຄ່າເພີ້ມ % :"
                type="number"
                prop="pps_vat"
                v-model="form.pps_vat"
              />
            </CCol>
            <CCol sm="6" v-if="userGroup == 'administrator'">
              <div class="form-group">
                <label>ສະແດງລາຍເຊັນ :</label>
                <v-select
                  prop="pps_signature"
                  v-model="form.pps_signature"
                  :options="signatureList"
                  :reduce="code => code.code"
                  label="message"
                />
              </div>
            </CCol>
            <CCol sm="1"></CCol>
            <CCol sm="1"></CCol>
            <CCol sm="10">
              <CInput label="ຂໍ້ຄວາມໃນ Remark ບິນ :" prop="pps_remark" v-model="form.pps_remark" />
            </CCol>
            <CCol sm="11">
              <div class="float-right">
                <CButton type="submit" variant="outline" color="warning">
                  <CIcon name="cil-save" />ບັນທຶກ
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>
 <script>
import { decrypt } from "@/libs/crypto";
export default {
  name: "ProposalFormUpdate",
  props: {
    pps: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        pps_vat: this.pps.pps_vat,
        pps_remark: this.pps.pps_remark,
        pps_signature: this.pps.pps_signature,
      };
    },
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      signatureList: [
        { code: 0, message: "ບໍ່ສະແດງ" },
        { code: 1, message: "ສະແດງ" },
      ],
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        id: this.pps.id,
        pps_vat: this.form.pps_vat,
        pps_remark: this.form.pps_remark,
        pps_signature: this.form.pps_signature,
        pps_by: decrypt(this.$store.getters.getFullName),
      });
    },
  },
};
</script>