<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CButton @click="print()" color="info" style="width: 9rem" class="float-right">
          <CIcon name="cil-print" />ພິມລາຍງານ
        </CButton>
      </CCol>
      <CCol sm="12">
        <hr />
      </CCol>
      <CCol sm="12">
        <div id="print-proposal-view">
          <CRow>
            <CCol></CCol>
            <CCol sm="3">
              <CImg src="images/3c_link_logo.png" width="100%" />
            </CCol>
            <CCol sm="1"></CCol>

            <CCol sm="12" class="text-center">
              <strong>
                ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ
                <br />ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ
              </strong>
            </CCol>
            <CCol sm="12">
              <br />
              <br />
            </CCol>
            <CCol sm="12" class="text-center">
              <h3>
                <u><b>Quotations</b></u>
              </h3>
            </CCol>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12">
                      <strong>
                        3c-link Technologies Sole Co., Ltd, Barn Home RD, Thana Village, Hadxayfong district, Vinetiane Capital
                        <br />Tel: +85620 22222933, 23376644, e-mail: laoftth@3c-linklaos.com
                      </strong>
                    </CCol>
                    <CCol sm="12">
                      <div class="my-4"></div>
                    </CCol>
                    <CCol sm="8">
                      <CCard border-color="dark" class="h-80">
                        <CCardBody>
                          <h5>TO : {{reportPps.cus_full_name}}</h5>
                          <strong>Address : {{reportPps.cus_address}} <br /> Tel : {{reportPps.cus_tel}}</strong>
                        </CCardBody>
                      </CCard>
                    </CCol>
                    <CCol sm="4">
                      <CCard border-color="dark" class="h-80">
                        <CCardBody>
                          <h5>NO : {{reportPps.pps_no}}</h5>
                          <strong class="text-right">Date : {{reportPps.pps_date}}</strong>
                          <div><br /></div>
                        </CCardBody>
                      </CCard>
                    </CCol>
                    <CCol sm="12">
                      <div class="my-4"></div>
                    </CCol>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລດ</strong>
                              <br />Item
                            </th>
                            <th class="text-center w-50">
                              <strong>ລາຍການ</strong>
                              <br />Description
                            </th>
                            <th class="text-center">
                              <strong>ຫົວໜ່ວຍ</strong>
                              <br />Unit
                            </th>
                            <th class="text-center">
                              <strong>ຈຳນວນ</strong>
                              <br />Quantity
                            </th>
                            <th class="text-center">
                              <strong>ລາຄາ</strong>
                              <br />
                              Unit Prices/{{reportPps.pps_curency}}
                            </th>
                            <th class="text-center w-25">
                              <strong>ຈຳນວນເງິນ</strong>
                              <br />
                              Amount/{{reportPps.pps_curency}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in reportPssList" :key="row.index">
                            <td class="text-center">{{row.index}}</td>
                            <td >{{row.service_name}}</td>
                            <td class="text-center">{{row.service_unit}}</td>
                            <td class="text-center">{{row.pps_amount | numFormat}}</td>
                            <td class="text-right">{{row.pps_price | numFormat(',000.00')}}</td>
                            <td class="text-right">{{row.pps_amount * row.pps_price | numFormat(',000.00')}}</td>
                          </tr>
                          <tr>
                            <td colspan="5" class="text-right">
                              <strong>Total:</strong>
                            </td>
                            <td class="text-right">
                              <strong>{{reportPps.pps_price_total | numFormat(',000.00')}}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" class="text-right">
                              <strong v-if="reportPps.pps_vat == 0">VAT :</strong>
                              <strong v-if="reportPps.pps_vat != 0">VAT {{reportPps.pps_vat}} %:</strong>
                            </td>
                            <td class="text-right">
                              <strong>{{reportPps.amount_vat | numFormat(',000.00')}}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" class="text-right">
                              <strong>Net Amount ({{reportPps.pps_curency}}) :</strong>
                            </td>
                            <td class="text-right">
                              <strong>{{reportPps.sum_total | numFormat(',000.00')}}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                    <CCol sm="12" class="my-0"></CCol>
                    <CCol sm="8">
                      <CCard border-color="dark" class="h-60">
                        <CCardBody>
                          <strong>Remark : {{reportPps.pps_remark}}</strong>
                        </CCardBody>
                      </CCard>
                    </CCol>
                    <CCol sm="4"></CCol>
                    <CCol sm="12" class="my-4"></CCol>
                    <CCol sm="8"></CCol>
                    <CCol sm="4" class="text-center"><u><strong>ຜູ້ອໍານວຍການ / DIRECTOR</strong></u></CCol>
                    <CCol sm="8"></CCol>
                    <CCol sm="4" class="float-center">
                      <br />
                      <CImg src="images/signature.jpeg" width="100%" v-if="reportPps.pps_signature == 1" />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  name: "ProposalView",
  props: {
    reportPps: {
      type: Object,
      required: true,
    },
    reportPssList: {
      type: Array,
      required: true,
    },
  },
  methods: {
     print() {
      this.$htmlToPaper("print-proposal-view");
    },
  },
};
</script>